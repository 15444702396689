import React from "react"
import { ImageBackground, Pressable, View } from 'react-native'
import { Button, Heading, space, Text, ThemeProvider, unboaredTheme, useNormalize } from "@unboared/base-ui.all"
import { useAuth, useAuthManager } from "./auth"
import { ExtraSignInButtonWithLogo } from "./components/button"
import BG from './assets/bg.png'
import { GlobalPopup, useGlobalPopup } from "./components/global_popup"
import { EmailPopup } from "./components/email_input"

const Auth = () => {
  const { loginWithGoogle } = useAuth()
  const { normalize } = useNormalize()
  const { open } = useGlobalPopup()

  return (
    <>
      <Heading style={{ marginBottom: normalize(space.small) }} text="Bienvenue sur Unboared" />
      {/* <Text style={{ marginTop: normalize(space.tiny), marginBottom: normalize(space.small) }} text="Sign in to have fun!" /> */}
      <ExtraSignInButtonWithLogo
        logo="https://cdn-icons-png.flaticon.com/512/2965/2965278.png"
        size={10}
        onPress={loginWithGoogle}
        text="Continuer avec Google &rarr;"
      />
      <Pressable onPress={() => open(<EmailPopup />)}>
        <Text style={{ marginTop: normalize(space.tiny) }} preset="light" text="Ou utilisez votre adresse email &rarr;" />
      </Pressable>
    </>
  )
}


const WaitList = () => {
  const { normalize } = useNormalize()

  return (
    <>
      {/* <Logo short size={80} /> */}
      <Heading style={{ fontSize: normalize(30) }} text="Waitlist" />
      <Heading type='h3' style={{ marginTop: normalize(space.small), }} text="Merci infiniment pour votre intérêt 🎉" />
      <Text style={{ maxWidth: normalize(350), textAlign: "center", fontSize: normalize(12), marginTop: normalize(space.tiny), marginBottom: normalize(space.small) }}
        text="Vous recevrez vos accès lors du lancement de la version officielle.
        Cependant, si vous ne voulez pas attendre, vous pouvez récupérer les accès auprès d'un ami bêta-testeur."
      />
      <Pressable onPress={() => window.location.replace("https://www.instagram.com/play.unboared/")}>
        <Text style={{ fontSize: normalize(12), color: "#FF2453", marginTop: normalize(space.tiny) }} preset="light" text="Suivez nous sur Instagram &rarr;" />
      </Pressable>
      <Pressable onPress={() => window.location.replace("https://discord.gg/QsJhQRQeFE")}>
        <Text style={{ fontSize: normalize(12), color: "#FF2453", marginTop: normalize(space.tiny) }} preset="light" text="Participez au channel Discord &rarr;" />
      </Pressable>
    </>
  )
}

export default function App() {
  const { auth } = useAuth()
  useAuthManager()

  return (
    <ThemeProvider theme={unboaredTheme.dark}>
      <View style={{ position: "absolute", width: '100%', height: '100%', backgroundColor: "#070219" }}>
      </View>
      <ImageBackground
        source={BG}
        resizeMode="cover"
        style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: "center", backgroundColor: unboaredTheme.dark.backgroundColor }}>
        <GlobalPopup />
        {auth === null ? <Auth /> : <WaitList />}
      </ImageBackground>
    </ThemeProvider >
  )
}