import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { firebaseConfig } from '~/config/const';

// Your web app's Firebase configuration
export const firebaseConfig = {

    apiKey: "AIzaSyCMiw0ST6sRUzcGCjltGE_q36gcxl6XF5k",

    authDomain: "unboared.firebaseapp.com",

    projectId: "unboared",

    storageBucket: "unboared.appspot.com",

    messagingSenderId: "803182974472",

    appId: "1:803182974472:web:8a1f208c65da07792763bd",

    measurementId: "G-82N796KB49"

};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);


export const urlNewsletterSubscription = 'https://b952e082.sibforms.com/serve/MUIEAAg-Tqyodqdtt4icyucfOpEoOnjvvq2Z2CejMSRQiZRKw7SzqoUbpCPswb82tEFcEQLZQPG8DdC-guVsSOLiF38TZwC6p8v_tImxap9RI9KzhO9mQt6L5Zzhp5K3Mm36052TEhMLx2_d_288QCVBTAq2V380pCaY5vORSSlqh4BLqLOSXEYZ8AJkSIDJZ026_a5ePQEqtPAo'