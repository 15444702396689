import React from "react"
import MainApp from "./src/app"

function App() {
  return (
    <MainApp />
  )
}

export default App
