import React, { useState } from 'react'
import { View } from 'react-native'
import { Logo } from '@unboared/design.brand.logo'
import { Button, Heading, space, TextInput, useNormalize } from '@unboared/base-ui.all'
import { useGlobalPopup } from './global_popup'
import { urlNewsletterSubscription, useAuth } from '../auth'

const BUTTON_WIDTH = 120

const subscribeToNewsletter = (email: string) => {

    let formData = new FormData();
    formData.append('EMAIL', email);


    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: 'POST',
        body: formData
    };
    fetch(
        urlNewsletterSubscription,
        requestOptions
    )
        .then(response => {
            console.log("success response")
            console.log(response)
        })
        .catch(error => {
            console.log('error')
            console.log(error)
            throw error
        })
}

export const EmailPopup = () => {
    const { close } = useGlobalPopup()
    const { normalize } = useNormalize()
    const [email, setEmail] = useState("")
    const { setAuth } = useAuth()

    const onSubscribe = () => {
        subscribeToNewsletter(email)
        setAuth(true)
        close()
    }

    return (
        <View style={{ flex: 1, alignItems: "center" }}>
            <Logo size={40} short />
            <Heading type='h3' text="Continuer avec l'email" />
            <TextInput style={{ marginVertical: normalize(space.small), width: normalize(2 * BUTTON_WIDTH + space.small) }} text={email} placeholder="you@example.com" onChangeText={setEmail} />
            <View style={{ flex: 1, flexDirection: 'row' }}>
                <Button style={{ width: normalize(BUTTON_WIDTH), marginRight: normalize(space.small) }} preset='secondary' text="Annuler" onPress={close} />
                <Button style={{ width: normalize(BUTTON_WIDTH) }} preset='primary' text="Valider" onPress={onSubscribe} />
            </View>
        </View>
    )
}