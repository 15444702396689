import React, { useEffect } from "react";

import { BlurredOverlay, Modal, space, Text, useNormalize } from "@unboared/base-ui.all";
import { useGlobalPopup } from "./useGlobalPopup";
import { Pressable } from "react-native";

export const GlobalPopup = () => {
    const content = useGlobalPopup(state => state.content);
    const { normalize } = useNormalize()

    return (
        <BlurredOverlay active={content}>
            <Modal>
                <>
                    <ClosePopup style={{
                        position: "absolute",
                        right: -normalize(space.smaller),
                        top: -normalize(space.smaller),
                    }} />
                    {content}
                </>
            </Modal>
        </BlurredOverlay>
    )
}

const ClosePopup = ({ style }: any) => {
    const close = useGlobalPopup(state => state.close);
    return (
        <Pressable style={style} onPress={close}>
            <Text preset="big" style={{ fontFamily: "OpenSansBold" }} text="x" />
        </Pressable>
    )
}